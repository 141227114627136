// x-release-please-start-version

import React from "react";
import { assets } from "@koble/assets/assets";
import { useIsMobile } from "@koble/hooks/src/IsMobile";
import { Col, Row, Space, Typography } from "antd";
import Link from "next/link";

import {
  AmericanExpressLogo,
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  MastercardLogo,
  TiktokLogo,
  VisaLogo,
} from "@/common/logos/logos";
import { PUBLIC_GRAY_GRADIENT_FROM_COLOR } from "@/common/publicColors";
import es from "@/lang/es";

const { Text } = Typography;
const PublicSwitchFooter = () => {
  const { isMobile } = useIsMobile();

  const SectionComponent = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: isMobile ? "center" : "start",
          marginBottom: isMobile ? 35 : 0,
        }}
      >
        <Space direction="vertical" align={isMobile ? "center" : "start"}>
          <Text
            style={{
              color: "white",
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            {es.switch.footer.sections.title}
          </Text>
          <Link
            href={es.switch.metadata.userStudentSlug}
            title={es.switch.footer.sections.userStudentsTitle}
          >
            <Text
              style={{
                color: "white",
              }}
            >
              {es.switch.footer.sections.userStudents}
            </Text>
          </Link>
          <Link
            href={es.switch.metadata.userRecruiterSlug}
            title={es.switch.footer.sections.userRecruitersTitle}
          >
            <Text
              style={{
                color: "white",
              }}
            >
              {es.switch.footer.sections.userRecruiters}
            </Text>
          </Link>
          <Link
            href={es.switch.footer.sections.termsAndConditionsLink}
            target="_blank"
            title={es.switch.footer.sections.termsAndConditionsTitle}
          >
            <Text
              style={{
                color: "white",
              }}
            >
              {es.switch.footer.sections.termsAndConditions}
            </Text>
          </Link>
          <Link
            href={es.switch.footer.sections.privacyPolicyLink}
            target="_blank"
            title={es.switch.footer.sections.privacyPolicyTitle}
          >
            <Text
              style={{
                color: "white",
              }}
            >
              {es.switch.footer.sections.privacyPolicy}
            </Text>
          </Link>
        </Space>
      </div>
    );
  };

  const ContactComponent = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: isMobile ? "center" : "start",
          marginBottom: isMobile ? 35 : 0,
        }}
      >
        <Space direction="vertical" align={isMobile ? "center" : "start"}>
          <Text
            style={{
              color: "white",
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            {es.switch.footer.contact.title}
          </Text>
          <a
            href={es.switch.footer.contact.phoneLink}
            target="_blank"
            rel="noreferrer"
            title={es.switch.footer.contact.phoneTitle}
          >
            <Text style={{ color: "white" }}>
              {es.switch.footer.contact.phone}
            </Text>
          </a>
          <a
            href={es.switch.footer.contact.emailLink}
            target="_blank"
            rel="noreferrer"
            title={es.switch.footer.contact.emailTitle}
          >
            <Text style={{ color: "white" }}>
              {es.switch.footer.contact.email}
            </Text>
          </a>
        </Space>
      </div>
    );
  };

  const PaymentComponent = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: isMobile ? "center" : "start",
        }}
      >
        <Text
          style={{
            color: "white",
            fontSize: 18,
            marginBottom: 5,
            fontWeight: 600,
          }}
        >
          {es.switch.footer.payments.title}
        </Text>
        <Space>
          <VisaLogo color="white" />
          <MastercardLogo color="white" />
          <AmericanExpressLogo color="white" />
        </Space>
        <Text
          style={{
            color: "white",
            marginTop: 35,
            marginBottom: 8,
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          Síguenos
        </Text>
        <Space>
          <a
            href={es.switch.footer.socialMedia.instagramLink}
            target="_blank"
            rel="noreferrer"
          >
            <InstagramLogo color="white" />
          </a>
          <a
            href={es.switch.footer.socialMedia.tikTokLink}
            target="_blank"
            rel="noreferrer"
          >
            <TiktokLogo color="white" />
          </a>
          <a
            href={es.switch.footer.socialMedia.linkedinLink}
            target="_blank"
            rel="noreferrer"
          >
            <LinkedinLogo color="white" />
          </a>
          <a
            href={es.switch.footer.socialMedia.facebookLink}
            target="_blank"
            rel="noreferrer"
          >
            <FacebookLogo color="white" />
          </a>
        </Space>
      </div>
    );
  };

  if (isMobile) {
    return (
      <div
        style={{
          width: "100%",
          paddingTop: 30,
          paddingBottom: 90,
          backgroundColor: PUBLIC_GRAY_GRADIENT_FROM_COLOR,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ marginBottom: 5 }}>
          <img
            alt={es.switch.footer.logoAlt}
            src={assets.KOBLE_LOGO_WHITE_WITH_TEXT}
            style={{ marginBottom: 35 }}
          />
          {/* <KobleLogo color="white" /> */}
        </div>
        <SectionComponent />
        <ContactComponent />
        <PaymentComponent />
      </div>
    );
  }

  return (
    <div
      style={{
        zIndex: 99,
        width: "100%",
        height: 300,
        backgroundColor: PUBLIC_GRAY_GRADIENT_FROM_COLOR,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Row
        style={{
          width: "100%",
        }}
        gutter={8}
        justify="center"
      >
        <Col xs={4}>
          <Space direction="vertical" size={4}>
            <img alt="koble" src={assets.KOBLE_LOGO_WHITE_WITH_TEXT} />
            <Typography.Text
              style={{ color: "white", fontSize: 13, fontWeight: 600 }}
            >
              V1.39.0
            </Typography.Text>
          </Space>
        </Col>
        <Col xs={4}>
          <SectionComponent />
        </Col>
        <Col xs={4}>
          <ContactComponent />
        </Col>
        <Col xs={4}>
          <PaymentComponent />
        </Col>
      </Row>
    </div>
  );
};

export default PublicSwitchFooter;
